import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Box from '../../components/box'
import Footer from '../../components/footer'
import Typography from '../../components/typography'
import RowLayout from '../../components/rowLayout'
import OperatorsPatternSvg from '../../assets/svg/operators-pattern.svg'
import CheckDarkIcon from '../../icons/checkDarkOperators'
import CheckPurpleIcon from '../../icons/checkPurpleOperators'
import OperatorsList from '../../containers/operatorsList'
import { H1, CopyBody, CopyBodyH2 } from '../../pages/index'
import { useRemoveHotjarScript } from '../../utils/hooks/useHotjarScript'
import OperatorSubscriptionBar from '../../components/operatorSubscriptionBar'

const OperatorsListing = ({ data }) => {
  const [signupSuccess, setSignupSuccess] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const el = event.target
    var formData = new FormData(el)
    fetch(
      'https://www.mailblast.io/lists/83f16537-de06-4f1f-8db8-05ca05636996/subscribers/create_external',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then(res => {
        if (res.status === 200) {
          setSignupSuccess(true)
        }
        return res.json()
      })
      .then(function (data) {
        if (data && data.error && data.error.length > 0) {
          alert(data.error[0])
        }
      })
  }

  useRemoveHotjarScript()

  return (
    <Container>
      <SEO
        title="Cult Operations - Pulse"
        description="Cult Operations is a newsletter that teaches leaders how to create high-performing teams, help them scale operations and navigate the challenges of the modern workplace."
        ogImage="/assets/ogimages/pulse-cult-operations.png"
        twImage="/assets/ogimages/twitter/pulse-cult-operations.png"
      />
      <Nav
        style={{
          background: '#FFFFFF',
          position: 'absolute'
        }}
      />
      <OperatorSubscriptionBar />
      {/* <OperatorsPatternSvg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 1,
          opacity: 0.5,
        }}
      />
      <HeroContainer>
        <Box id="hero-inner">
          <H1>Cult Operations</H1>
          <CopyBodyH2
            style={{
              marginTop: '0',
              marginBottom: '0',
              maxWidth: '100%',
            }}
          >
            Cult Operations is a weekly newsletter designed to help leaders and
            operators upgrade their skills. We cover topics that teach you how
            to create high-performing teams, help you scale operations
            post-product/market fit and navigate the challenges of the modern
            workplace.
          </CopyBodyH2>
          <Box display="flex" flexDirection="column">
            <FormContainer>
              {signupSuccess ? (
                <Typography textType="heading-medium" style={{ color: '#fff' }}>
                  {' '}
                  Noice, you’re in! Watch out for our first email.
                </Typography>
              ) : (
                <>
                  <div className="mb-embed">
                    <form
                      method="post"
                      action=""
                      className="mb-form"
                      noValidate="novalidate"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-form-group">
                        <input
                          type="email"
                          name="subscriber[email]"
                          className="mb-email"
                          required
                          aria-required="true"
                          placeholder="your@email.com"
                        />
                      </div>
                      <div className="mb-form-group-tk" aria-hidden="true">
                        <input
                          type="text"
                          name="mb_83f16537de064f1f8db805ca05636996"
                          tabIndex={-1}
                          defaultValue
                        />
                      </div>
                      <div className="mb-form-group mb-subscribe">
                        <button type="submit" className="button mb-subscribe">
                          Subscribe
                        </button>
                      </div>
                    </form>
                    <script src="https://cdn.mailblast.io/javascripts/mb-embed-1-2.js"></script>
                  </div>
                </>
              )}
            </FormContainer>
            <p id="hero-subtext">One weekly episode, one weekly email.</p>
            <OperatorSocials>
              <a
                href="https://www.twitter.com/cultoperations"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.548 21.962C16.6052 21.962 21.5575 14.2681 21.5575 7.59613C21.5575 7.37736 21.5529 7.16044 21.5437 6.94352C22.5111 6.22475 23.3428 5.33956 24 4.32936C23.1175 4.7309 22.1686 5.00229 21.1726 5.12321C22.207 4.48368 22.9757 3.49209 23.3372 2.3309C22.374 2.91766 21.3161 3.33246 20.2108 3.55675C19.7557 3.05525 19.2009 2.65432 18.582 2.37965C17.963 2.10498 17.2935 1.96261 16.6163 1.96167C13.8969 1.96167 11.6926 4.22321 11.6926 7.0109C11.6926 7.40598 11.7351 7.79182 11.82 8.16106C7.72615 7.94967 4.09846 5.94105 1.66985 2.88567C1.23152 3.65979 1.00186 4.53453 1.00338 5.42413C1.00338 7.17521 1.87292 8.72229 3.19477 9.6269C2.41087 9.60164 1.64512 9.38453 0.964615 8.99459V9.05921C0.964615 11.5054 2.66123 13.5463 4.91446 14.0087C4.18988 14.211 3.42798 14.2408 2.68985 14.0955C3.31754 16.1014 5.13508 17.5617 7.29046 17.6032C6.27724 18.4222 5.10977 19.0292 3.85738 19.3882C2.60499 19.7472 1.29323 19.8509 0 19.6931C2.23926 21.1726 4.86407 21.9613 7.548 21.9611"
                    fill="white"
                  />
                </svg>
                @CultOperations
              </a>
              <Box marginLeft="1.5rem" marginRight="1.5rem">
                /
              </Box>
              <a>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.0005 12.0005C23.0005 12.0005 23.0005 8.15049 22.5405 6.42049C22.2905 5.47049 21.5405 4.72049 20.6005 4.46049C18.8805 4.00049 12.0005 4.00049 12.0005 4.00049C12.0005 4.00049 5.12049 4.00049 3.40049 4.46049C2.45049 4.71049 1.71049 5.47049 1.46049 6.42049C1.00049 8.15049 1.00049 12.0005 1.00049 12.0005C1.00049 12.0005 1.04049 15.8505 1.50049 17.5805C1.75049 18.5305 2.50049 19.2805 3.45049 19.5405C5.16049 20.0005 12.0405 20.0005 12.0405 20.0005C12.0405 20.0005 18.9205 20.0005 20.6405 19.5405C21.5905 19.2905 22.3305 18.5305 22.5805 17.5805C23.0405 15.8505 23.0005 12.0005 23.0005 12.0005ZM10.0005 15.2705V8.73049L15.5005 12.0005L10.0005 15.2705Z"
                    fill="white"
                  />
                </svg>
                CultOperations
              </a>
            </OperatorSocials>
          </Box>
        </Box>
      </HeroContainer>

      <RowLayout id="what-section-container">
        <Box id="to-learn-container">
          <h3 className="header">What you’ll learn</h3>
          <p className="copy-body">
            While building Pulse we have the opportunity to talk with some of
            the best operators. Cult Operations is our way to share what we
            learn. Follow to know:
          </p>
          <ul className="copy-list">
            <li>
              <CheckDarkIcon />
              How to operate remote and hybrid organizations
            </li>
            <li>
              <CheckDarkIcon />
              How to scale from single-digit employees to +500
            </li>
            <li>
              <CheckDarkIcon />
              How to elevate your comms game as a leader
            </li>
            <li>
              <CheckDarkIcon />
              How to build modern high-output teams
            </li>
          </ul>
        </Box>
        <Box id="to-expect-container">
          <h3 className="header">What to expect</h3>
          <p className="copy-body">
            One episode per week. One email per week. That’s it. Episodes are
            essays that deconstruct strategies, tactics, and principles behind
            some of the world’s best companies.
          </p>
          <ul className="copy-list">
            <li>
              <CheckPurpleIcon />
              Practical how-tos on tools, people, and processes
            </li>
            <li>
              <CheckPurpleIcon />
              Breakdowns of tactics and principles from renowned orgs
            </li>
            <li>
              <CheckPurpleIcon />
              Dive deep with industry-leading operators
            </li>
            <li>
              <CheckPurpleIcon />
              Stories of operational excellence
            </li>
          </ul>
        </Box>
      </RowLayout> */}

      <RowLayout style={{ background: 'none' }}>
        <OperatorsList data={data} />
      </RowLayout>
      <Footer />
    </Container>
  )
}

export default OperatorsListing

export const operatorsListingQuery = graphql`
  query OperatorsListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(content/operators/).*\\\\.md$/" }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM Do, YYYY")
            slug
            operatorlabel {
              frontmatter {
                name
                slug
              }
            }
            author {
              frontmatter {
                firstName
                lastName
                cover {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                slug
                email
                twitterHandle
                bio
              }
            }
          }
        }
      }
    }
    spotlightMarkdownRemark: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fileAbsolutePath: { regex: "/(content/operators/).*\\\\.md$/" }
        frontmatter: { spotlight: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM Do, YYYY")
            slug
            operatorlabel {
              frontmatter {
                name
                slug
              }
            }
            author {
              frontmatter {
                firstName
                lastName
                cover {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                slug
                email
                twitterHandle
                bio
              }
            }
          }
        }
      }
    }
  }
`

const Container = styled.div`
  #what-section-container {
    background: none;

    @media (max-width: 72.5rem) {
      flex-direction: column;
    }

    @media (max-width: 90rem) {
      padding-left: 0;
      padding-right: 0;
    }

    .header {
      font-family: ${props => props.theme.typography.fontfamilyGTBold};
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.8125rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    .copy-body {
      font-size: 1rem;
      line-height: 2rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    .copy-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-bottom: 1.25rem;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    #to-learn-container {
      flex: 1;
      padding: 8.9375rem 5rem;
      box-sizing: border-box;
      background: #f6f6f6;

      .copy-body,
      .copy-list {
        color: rgba(0, 0, 0, 0.7);
      }

      @media (max-width: 31.25rem) {
        padding: 4rem 2rem;
      }
    }

    #to-expect-container {
      flex: 1;
      padding: 8.9375rem 5rem;
      box-sizing: border-box;
      background: #0c0c07;

      .header {
        color: #ffffff;
      }
      .copy-body,
      .copy-list {
        color: rgba(255, 255, 255, 0.7);
      }

      @media (max-width: 31.25rem) {
        padding: 4rem 2rem;
      }
    }
  }
`

const HeroContainer = styled(RowLayout)`
  background: #9601ff;
  max-height: max-content;
  padding-top: 4.375rem;
  min-height: 40.75rem;
  max-height: max-content;
  padding-bottom: 2rem;
  box-sizing: border-box;

  #hero-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  #hero-subtext {
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }
`

export const FormContainer = styled.div`
  margin-top: ${props => props.theme.spacing(7)};
  margin-bottom: ${props => props.theme.spacing(1)};
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.laptop} {
    flex-direction: row;
  }

  .mb-embed {
    display: flex;
    width: 100%;
  }

  .mb-form-group {
    display: flex;
    flex: 1;
  }

  .mb-embed .mb-form-group input {
    background: #b555f8;
    border: 3px solid #ffffff;
    box-sizing: border-box;
    width: 100%;
    padding: 1.125rem 1.125rem;
    box-sizing: border-box;
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #fff;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    :focus {
      background: none;
    }

    @media ${props => props.theme.device.tablet} {
      width: 23.125rem;
    }
  }

  .mb-embed .mb-form-group button {
    padding: 1.125rem 1.125rem;
    width: 100%;
    background: #ffffff;
    font-family: ${props => props.theme.typography.fontFamilyGT};
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: capitalize;
    color: #9601ff;
    border: 3px solid #ffffff;
    cursor: pointer;

    @media ${props => props.theme.device.tablet} {
      width: 11.8125rem;
    }

    :hover {
      opacity: 0.9;
    }
  }

  .mb-embed .mb-form-group-tk {
    position: absolute;
    left: -312.5rem;
  }

  .mb-form {
    display: flex;
    flex-wrap: wrap;
  }
`

const OperatorSocials = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.purple[100]};

  a {
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.purple[100]};
    cursor: pointer;
    text-decoration: none;
  }

  svg {
    margin-right: 0.5rem;
  }
`
