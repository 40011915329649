import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1.667a8.333 8.333 0 100 16.666 8.333 8.333 0 000-16.666z"
        fill="#B555F8"
        stroke="#B555F8"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.667 9.88l2.083 2.084L13.214 7.5"
        stroke="#fff"
        strokeOpacity={0.7}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
