import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Box from './box'

const StyledLink = styled(Link)`
  text-decoration: unset;
  color: unset;

  &:hover {
    .lower-section {
      .read-label {
        transform: translateX(0rem);
        opacity: 1;
      }
    }
  }
`

const Article = styled.article`
  width: 20.625rem;
  height: 22.5rem;
  box-shadow: ${props => props.theme.shadows[2]};
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.875rem 0.875rem;
  display: flex;
  flex-direction: column;

  .upper-section {
    height: 9.25rem;
    overflow: hidden;

    img {
      border-top-left-radius: 0.875rem;
      border-top-right-radius: 0.875rem;
    }
  }

  .middle-section {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    flex: 1;
    max-height: 10.75rem;
    overflow: hidden;
  }

  .lower-section {
    width: 100%;
    height: 2.5rem;
    position: relative;
    overflow: hidden;

    .label {
      padding-left: 1.5rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      text-transform: uppercase;
      color: ${props => props.theme.core.content.inverseSecondary};
    }

    .read-label {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #ffffff;
      padding: 0.625rem 2rem;
      background: #9601ff;
      border-radius: 0.875rem 0rem;
      position: absolute;
      right: 0;
      transition: all 200ms ease;
      opacity: 0;
      transform: translateX(100%);
    }
  }
`

const Title = styled.h3`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${props => props.theme.core.content.primary};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Excerpt = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: normal;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${props => props.theme.core.content.secondary};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const OperatorsCard = ({ to, title, cover, excerpt, label }) => (
  <StyledLink to={to}>
    <Article>
      {cover && (
        <div className="upper-section">
          <Img
            fluid={cover.childImageSharp.fluid}
            alt={title}
            style={{ maxWidth: '100%', height: '9.25rem' }}
          />
        </div>
      )}
      <div className="middle-section">
        <Title style={{ marginTop: '0rem', marginBottom: '0.5rem' }}>
          {title}
        </Title>
        <Box marginTop="1rem" />
        <Excerpt>{excerpt}</Excerpt>
      </div>
      <div className="lower-section">
        <span className="label">{label}</span>
        <span className="read-label">Read -&gt;</span>
      </div>
    </Article>
  </StyledLink>
)

export default OperatorsCard
